<template>
  <section id="hero">
    <v-img
      :min-height="minHeight"
      aspect-ratio="16/9"
      :src="require('@/assets/principal.jpg')"
      class="white--text"
      gradient="to right, rgba(5, 11, 31, .3), rgba(5, 11, 31, .0)"
    >
      <v-container class="fill-height px-4 py-12">
        <v-responsive
          class="d-flex align-center"
          height="100%"
          max-width="700"
          width="100%"
        >
          <base-heading title="ARTESANOS DE ESPECIALIDADES" />

          <base-body>
            La calidad de todos sus productos, el servicio a sus clientes y una elaboración artesana son las características que diferencian a Cantdelimar como una empresa sólida que apuesta por ofrecer un producto que destaque por su incomparable sabor y calidad, así como por una excelente presentación.
          </base-body>

          <div
            :class="$vuetify.breakpoint.smAndDown ? 'flex-column align-start' : 'align-center'"
            class="d-flex flex-wrap"
          >
            <base-btn :to="{ name: 'Contacto' }">
              Contáctanos
            </base-btn>

            <span class="font-weight-bold ml-6 mr-4 my-4">|</span>

            <base-btn
              :to="{ name: 'Productos' }"
              :ripple="false"
              class="pa-1"
              height="auto"
              text
            >
              Descubre nuestros productos
            </base-btn>
          </div>
        </v-responsive>
      </v-container>
    </v-img>
  </section>
</template>

<script>
  export default {
    name: 'SectionHero',

    provide: {
      theme: { isDark: true },
    },

    computed: {
      minHeight () {
        const height = this.$vuetify.breakpoint.mdAndUp ? '100vh' : '50vh'

        return `calc(${height} - ${this.$vuetify.application.top}px)`
      },
    },
  }
</script>
